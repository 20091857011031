<template>
  <div>
    <div>当前页面不存在，请检查网址是否正确</div>
    <div>
      <router-link to="/">返回报名首页 </router-link>
    </div>
  </div>
</template>
<script>
/* eslint-disable */

export default {
  name: "404",

  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style  lang="scss">
</style>
